import axios from 'axios';
import Cookies from 'universal-cookie';
import qs from 'qs';

import { AUTH_TOKEN_NAME } from './constants';
import { isReseller } from './utils';

const cookies = new Cookies();
let cookiesOptions = { maxAge: 30 * 60, path: '/' };
if (process.env.NODE_ENV === 'production') cookiesOptions.secure = true;

axios.defaults.baseURL = process.env.REACT_APP_HOST;

axios.interceptors.request.use(
  function (config) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    let token = cookies.get(AUTH_TOKEN_NAME);
    if (token) cookies.set(AUTH_TOKEN_NAME, token, cookiesOptions);
    return response;
  },
  function (error) {
    if (window.location.pathname !== '/login' && error.response === undefined)
      window.location.href = '/logout';
    return Promise.reject(error);
  }
);

export const URLS = {
  AUTH: {
    LOGIN: '/login',
    LOGOUT: '/logout',
    RESET: '/accounts/:id/reset_password',
  },
  ACCOUNTS: {
    SEARCH: '/accounts',
    DETAILS: '/accounts/:id',
    SMART_METER_DETAILS: '/smart-meters/:integratorId/accounts/:accountId',
    RESTRICTED_DETAILS: '/accounts/:id/restricted',
    TEMPORARY_PASSWORD: {
      DELETE: '/accounts/:id/temporary_password/suppress',
      GENERATE: '/accounts/:id/temporary_password',
    },
    INSTALLATIONS: '/accounts/installed',
    TERMS_AGREEMENT: '/terms/:id/agreement',
  },
  DEVICES: {
    REPORT: '/devices/report',
  },
  SENSORS: {
    DETAILS: '/sensors/:id',
    RESET: {
      MACHINE_LEARNING: '/sensors/reset_pre_live',
      FULL: '/sensors/notimplementedyet',
    },
    CHANGE_LOCALE: '/sensors/change_locale',
    EVENTS_EXPORTING: {
      SEND_TO_EMAIL: '/sensors/events/exporting',
      HISTORY: '/sensors/events/exporting_history',
    },
    REPORTS_GENERATION_STATUS: '/stock/report/data/:sensorId/history',
    EVENTS: '/sensors/events',
    PURGE: '/sensors/:id/purge',
  },
  SMART_METERS: {
    SEARCH: '/smart-meters',
    DETAILS: '/smart-meters/:integratorId/sensors/:sensorId',
    PROPERTY_DETAILS: {
      GET: '/smart-meters/metadata/property',
      PUT: '/smart-meters/metadata/property',
      APPLIANCES: {
        GET: '/smart-meters/metadata/appliances',
        PUT: '/smart-meters/metadata/appliances',
      },
    },
    SMART_METER_APP: '/smart-meters/accounts',
    SETUP_VALIDATE: '/smart-meters/:integratorId/setup/validate',
    SETUP: '/smart-meters/:integratorId/setup',
    SETUP_EXAMPLE: '/smart-meters/setup/example-file',
  },
  COUNTRIES: {
    ADD: '/countries',
    EDIT: '/countries',
    GENERAL: '/countries/general',
    SEARCH: '/countries',
    SUMMARY: '/countries/summary',
    AVAILABLE: '/countries/available',
  },
  DISAGGREGATION: {
    SEARCH: '/disaggregation/logs',
  },
  LOCALES: {
    SEARCH: '/locales',
  },
  TIMEZONES: {
    SEARCH: '/time_zones',
  },
  RESELLERS: {
    ADD: '/resellers',
    EDIT: '/resellers',
    SEARCH: '/resellers',
    SEARCH_PAGINATION: '/resellers/pagination',
    USERS: {
      DELETE: '/resellers/users/:resellerId/:username',
      DETAILS: '/resellers/users/:resellerId/:username',
      GENERAL: '/resellers/users/:resellerId',
    },
    INTEGRATIONS: {
      ADD: '/resellers/integrations',
      DELETE: '/resellers/integrations/:integrationId',
      PERMISSIONS: {
        ADD: '/resellers/integrations/permissions',
        DELETE: '/resellers/integrations/permissions',
      },
      BUILDING_MONITORING: {
        USERS: {
          ADD: '/resellers/integrations/building_monitoring/users',
          DELETE: '/resellers/integrations/building_monitoring/users',
          DISABLE_ALL:
            '/resellers/integrations/building_monitoring/users/disable_all',
          ENABLE_ALL:
            '/resellers/integrations/building_monitoring/users/disable_all',
          GET: '/resellers/integrations/:id/building_monitoring/users',
        },
      },
    },
  },
  STOCK: {
    DETAILS: '/stock/:id',
    GENERAL_STATUS: '/stock/general_status',
    PROPERTY_DETAILS: {
      GET: '/metadata/property',
      PUT: '/metadata/property',
      APPLIANCES: {
        GET: '/metadata/appliances',
        PUT: '/metadata/appliances',
      },
    },
    REPORT: {
      CREATE: '/stock/report/create-report',
      DATES_HISTORY: '/stock/report/:id/dates-history',
      HISTORY: '/stock/report/:id/history',
      PURGE: '/stock/report/purge-report/:id/:token',
      SEND: '/stock/report/send-report',
    },
    ADD: {
      CONFIRM: '/stock',
      UPLOAD: '/stock/fromFile',
    },
    EDIT: '/stock/:id',
    ALLOCATE: {
      CHECK: '/stock/allocation/check',
      CONFIRM: '/stock/allocation/allocate',
    },
    DEALLOCATE: '/stock/allocation/deallocate',
    CHANGE: {
      FIND_PREVIOUS:
        '/stock/allocation/change_reseller/find_previous_resellers',
      CONFIRM: '/stock/allocation/change_reseller',
    },
    EXAMPLE: '/stock/example_file',
    SEARCH: '/stock',
    SUMMARY: '/stock/summary',
    PROVIDER: {
      DELETE: '/providers/:id',
      DETAILS: '/providers/:id',
      SEARCH: '/providers/countries/:isoCountryCode',
      ADD: '/providers',
      EDIT: '/providers',
    },
    BLOCK: {
      CONFIRM: '/stock/sensors/block',
      SEARCH_BY_ID: '/stock/sensors/:id/blocked_status',
      SEARCH: '/stock/sensors/blocked_status',
    },
    UNBLOCK: {
      CONFIRM: '/stock/sensors/unblock',
    },
    ACTIVATE_SMART_CABLE: '/stock/:sensorId/status/:status',
  },
  SUBSCRIPTION: {
    DETAILS: '/subscription/:id',
    GENERAL: '/subscription',
    ORDER: {
      ATTACH: {
        DETAILS: '/subscription/order/:id/attach/:attachId',
        GENERAL: '/subscription/order/:id/attach',
      },
      DETAILS: '/subscription/order/:id',
      GENERAL: '/subscription/order',
      SENSORS: {
        ADD: '/subscription/order/:id/sensors',
        REMOVE: '/subscription/order/:id/sensors',
      },
      DEADLINES: {
        LIST: '/subscription/order/deadlines',
        RENEW: '/subscription/order/:id/renew',
      },
    },
  },
  TARIFFS: {
    SEARCH: '/tariffs',
    SMART_METER_SEARCH: '/smart-meters/:integratorId/tariffs/:sensorId',
  },
  USER: {
    DELETE: '/user/:username',
    DETAILS: '/user/:username',
    GENERAL: '/user',
  },
  BEHAVIOURAL_MONITORING: {
    GET: '/behavioural_monitoring/:id',
    ENABLE: '/behavioural_monitoring/enable/:id',
    DISABLE: '/behavioural_monitoring/disable/:id',
    GET_INFO: '/behavioural_monitoring/information/:id',
  },
};

// Auth
const login = (user, pass) => {
  let token = btoa(`${user}:${pass}`);
  return axios({
    headers: {
      Authorization: 'Basic ' + token,
    },
    method: 'post',
    url: URLS.AUTH.LOGIN,
  });
};

const logout = () => {
  return axios.delete(URLS.AUTH.LOGOUT);
};

const authResetPassword = (sensorId, integratorId) => {
  if (integratorId) {
    const params = {
      integratorId,
    };

    return axios.post(URLS.AUTH.RESET.replace(':id', sensorId), null, {
      params: params,
    });
  }

  return axios.post(URLS.AUTH.RESET.replace(':id', sensorId));
};
// /Auth

// Accounts
const accountsSearch = ({ resellerId, searchBy, query, pagesLastId }) => {
  let params = {
    resellerId,
    searchBy,
    query,
    pagesLastId,
  };

  if (!resellerId) {
    delete params.resellerId;
  }

  return axios.get(URLS.ACCOUNTS.SEARCH, {
    params: params,
  });
};

const accountDetails = (accountId) => {
  return axios.get(URLS.ACCOUNTS.DETAILS.replace(':id', accountId));
};

const accountSmartMeterDetails = (accountId, integratorId) => {
  return axios.get(
    URLS.ACCOUNTS.SMART_METER_DETAILS.replace(
      ':integratorId',
      integratorId
    ).replace(':accountId', accountId)
  );
};

const accountRestrictedDetails = (accountId) => {
  return axios.get(URLS.ACCOUNTS.RESTRICTED_DETAILS.replace(':id', accountId));
};

const accountTemporaryPasswordDelete = (id, integratorId) => {
  if (integratorId) {
    const params = {
      integratorId,
    };

    return axios.delete(
      URLS.ACCOUNTS.TEMPORARY_PASSWORD.DELETE.replace(':id', id),
      {
        params: params,
      }
    );
  }

  return axios.delete(
    URLS.ACCOUNTS.TEMPORARY_PASSWORD.DELETE.replace(':id', id)
  );
};

const accountTemporaryPasswordGenerate = (id, integratorId) => {
  if (integratorId) {
    const params = {
      integratorId,
    };

    return axios.post(
      URLS.ACCOUNTS.TEMPORARY_PASSWORD.GENERATE.replace(':id', id),
      null,
      {
        params: params,
      }
    );
  }

  return axios.post(
    URLS.ACCOUNTS.TEMPORARY_PASSWORD.GENERATE.replace(':id', id)
  );
};

const accountTermsAgreement = (id) =>
  axios.get(URLS.ACCOUNTS.TERMS_AGREEMENT.replace(':id', id));

const accountInstallationsSearch = (
  search,
  searchBy,
  device,
  granularity,
  date,
  startDate,
  page
) => {
  const params = {
    device: device,
    granularity: granularity,
    from: date,
    searchBy: searchBy,
    query: search,
  };

  if (page) params.pagesLastId = page;

  if (granularity === 'WEEK') {
    params.from = startDate;
  }

  return axios.get(URLS.ACCOUNTS.INSTALLATIONS, {
    params: params,
  });
};
// /Accounts

// Smart Meters
const smartMetersSearch = ({
  resellerId,
  searchBy,
  query,
  pagesLastId,
  device,
}) => {
  const params = {
    resellerId,
    searchBy,
    query,
    device,
    pagesLastId,
  };

  if (!resellerId) {
    delete params.resellerId;
  }

  return axios.get(URLS.SMART_METERS.SEARCH, { params });
};

const smartMetersDetails = (sensorId, integratorId) => {
  return axios.get(
    URLS.SMART_METERS.DETAILS.replace(':integratorId', integratorId).replace(
      ':sensorId',
      sensorId
    )
  );
};

const smartMeterPropertyDetails = (sensorId, integratorId) => {
  return axios.get(URLS.SMART_METERS.PROPERTY_DETAILS.GET, {
    params: { integrator_id: integratorId, device_id: sensorId },
  });
};

const createOrChangeSmartMeterPropertyDetails = (
  sensorId,
  integratorId,
  payload
) => {
  return axios.put(
    URLS.SMART_METERS.PROPERTY_DETAILS.PUT,
    { ...payload },
    { params: { integrator_id: integratorId, device_id: sensorId } }
  );
};

const smartMeterPropertyAppliances = (sensorId, integratorId) => {
  return axios.get(URLS.SMART_METERS.PROPERTY_DETAILS.APPLIANCES.GET, {
    params: { integrator_id: integratorId, device_id: sensorId },
  });
};

const changeSmartMeterPropertyAppliances = (id, integratorId, payload) => {
  return axios.put(
    URLS.SMART_METERS.PROPERTY_DETAILS.APPLIANCES.PUT,
    { ...payload },
    { params: { integrator_id: integratorId, device_id: id } }
  );
};

const activateSmartMeterApp = ({
  integratorId,
  sensorId,
  email,
  userDefinedLanguage,
}) => {
  return axios.put(URLS.SMART_METERS.SMART_METER_APP, {
    integratorId: integratorId,
    sensorId: sensorId,
    email: email,
    userDefinedLanguage: userDefinedLanguage,
  });
};

const smartMeterSetupValidate = ({ integratorId, data }) => {
  return axios.post(
    URLS.SMART_METERS.SETUP_VALIDATE.replace(':integratorId', integratorId),
    data
  );
};

const smartMeterSetup = ({ integratorId, data }) => {
  return axios.post(
    URLS.SMART_METERS.SETUP.replace(':integratorId', integratorId),
    data
  );
};

const getSmartMeterSetupExample = () => {
  return axios.get(URLS.SMART_METERS.SETUP_EXAMPLE, {
    responseType: 'arraybuffer',
  });
};

// /Smart Meters

const resetMachineLearning = (sensorId, phases) => {
  return axios.post(URLS.SENSORS.RESET.MACHINE_LEARNING, {
    sensorId: sensorId,
    phases: phases,
  });
};

const fullReset = () => {
  return undefined;
};

const devicesReport = ({ integratorId, type }) => {
  const params = {
    integratorId,
    type,
  };

  return axios.get(URLS.DEVICES.REPORT, { params });
};

const sensorDetails = (sensorId) => {
  return axios.get(URLS.SENSORS.DETAILS.replace(':id', sensorId));
};

const changeLocale = (sensorId, countryIsoCode, isoLocaleCode, timeZoneId) => {
  return axios.put(URLS.SENSORS.CHANGE_LOCALE, {
    sensorId: sensorId,
    countryCode: countryIsoCode,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
  });
};

const tariffSearch = (sensorId) => {
  const params = {
    sensorId,
  };

  return axios.get(URLS.TARIFFS.SEARCH, { params });
};

const smartMeterTariffSearch = (sensorId, integratorId) => {
  return axios.get(
    URLS.TARIFFS.SMART_METER_SEARCH.replace(':sensorId', sensorId).replace(
      ':integratorId',
      integratorId
    )
  );
};

const sensorEventsExporting = (sensorId, fromDate, toDate, email) => {
  const params = {
    from: fromDate,
    to: toDate,
    sensor_id: sensorId,
  };

  if (email) params.email = email.toLowerCase();

  return axios.get(URLS.SENSORS.EVENTS_EXPORTING.SEND_TO_EMAIL, { params });
};

const sensorEventsExportingHistory = (sensorId) => {
  return axios.get(URLS.SENSORS.EVENTS_EXPORTING.HISTORY, {
    params: {
      sensor_id: sensorId,
    },
  });
};

const reportsGenerationStatus = (sensorId, integratorId) => {
  const params = integratorId ? { integrator_id: integratorId } : {};

  return axios.get(
    URLS.SENSORS.REPORTS_GENERATION_STATUS.replace(':sensorId', sensorId),
    {
      params,
    }
  );
};

const sensorEventsDelete = (sensorId, fromDate, toDate) => {
  const params = {
    from: fromDate,
    to: toDate,
    sensor_id: sensorId,
  };

  return axios.delete(URLS.SENSORS.EVENTS, { params });
};

// GDPR
const purgeSensor = (sensorId) => {
  return axios.delete(URLS.SENSORS.PURGE.replace(':id', sensorId));
};

const stockDetails = (id) => {
  return axios.get(URLS.STOCK.DETAILS.replace(':id', id));
};

const stockGeneralStatus = (
  category,
  country,
  phases,
  resellerId,
  status,
  device
) => {
  let params = {};
  if (category) params.category = category;
  if (country) params.iso_country_code = country;
  if (phases) params.phases = phases;
  if (resellerId) params.reseller_id = resellerId;
  if (status) params.status = status;
  if (device) params.device = device;

  return axios.get(URLS.STOCK.GENERAL_STATUS, { params: params });
};

const stockPropertyDetails = (id) => {
  return axios.get(URLS.STOCK.PROPERTY_DETAILS.GET, {
    params: { sensor_id: id },
  });
};

const changeStockPropertyDetails = (id, payload) => {
  return axios.put(
    URLS.STOCK.PROPERTY_DETAILS.PUT,
    { ...payload },
    { params: { sensor_id: id } }
  );
};

const stockPropertyAppliances = (id) => {
  return axios.get(URLS.STOCK.PROPERTY_DETAILS.APPLIANCES.GET, {
    params: { sensor_id: id },
  });
};

const changeStockPropertyAppliances = (id, payload) => {
  return axios.put(
    URLS.STOCK.PROPERTY_DETAILS.APPLIANCES.PUT,
    { ...payload },
    { params: { sensor_id: id } }
  );
};

const stockReportHistory = ({ id, page, datesHistory, integratorId }) => {
  let fn = URLS.STOCK.REPORT.DATES_HISTORY,
    params = {};

  if (!datesHistory) {
    fn = URLS.STOCK.REPORT.HISTORY;
    params = { page: page };
  }

  if (integratorId) {
    params = {
      ...params,
      integratorId,
    };
  }

  return axios.get(fn.replace(':id', id), { params: params });
};

const stockReportCreate = ({ sensorId, layout, month, year, integratorId }) => {
  const body = {
    layout: layout,
    month: month,
    sensorId: sensorId,
    year: year,
  };

  if (integratorId) {
    body.integratorId = integratorId;
  }

  return axios.post(URLS.STOCK.REPORT.CREATE, body);
};

const stockReportSend = ({
  sensorId,
  email,
  layout,
  month,
  year,
  integratorId,
}) => {
  const body = {
    layout: layout,
    month: month,
    sensorId: sensorId,
    year: year,
    integratorId,
  };

  if (email) {
    body.email = email.toLowerCase();
  }

  if (integratorId) {
    body.integratorId = integratorId;
  }

  return axios.post(URLS.STOCK.REPORT.SEND, body);
};

const stockReportPurge = ({ id, token, integratorId }) => {
  if (integratorId) {
    const params = {
      integratorId,
    };

    return axios.post(
      URLS.STOCK.REPORT.PURGE.replace(':id', id).replace(':token', token),
      null,
      {
        params: params,
      }
    );
  }

  return axios.post(
    URLS.STOCK.REPORT.PURGE.replace(':id', id).replace(':token', token)
  );
};

// Country
const countrySearch = (search, searchBy) => {
  const params = { query: search, searchBy: searchBy };

  return axios.get(URLS.COUNTRIES.SEARCH, {
    params: params,
  });
};

const countryAdd = (isoCode, name, isoLocaleCode, timeZoneId, tariff) => {
  return axios.post(URLS.COUNTRIES.ADD, {
    isoCode: isoCode,
    name: name,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
    tariff: tariff,
  });
};

const countryEdit = (isoCode, name, isoLocaleCode, timeZoneId, tariff) => {
  return axios.put(URLS.COUNTRIES.EDIT, {
    isoCode: isoCode,
    name: name,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
    tariff: tariff,
  });
};

const countrySummary = () => {
  return axios.get(URLS.COUNTRIES.SUMMARY);
};

const countryGeneral = () => {
  return axios.get(URLS.COUNTRIES.GENERAL);
};

const countriesAvailable = () => {
  return axios.get(URLS.COUNTRIES.AVAILABLE);
};

const localesSearch = (isoCountryCode) => {
  return axios.get(URLS.LOCALES.SEARCH, {
    params: {
      isoCountryCode,
    },
  });
};

const timeZonesSearch = (isoCountryCode) => {
  return axios.get(URLS.TIMEZONES.SEARCH, {
    params: {
      isoCountryCode,
    },
  });
};

// Resellers
const resellersAdd = (name, country, email, phone, contact, notes) => {
  return axios.post(URLS.RESELLERS.ADD, {
    contactName: contact,
    countryCode: country,
    email: email.toLowerCase(),
    name: name,
    notes: notes,
    phone: phone,
  });
};

const resellersEdit = (id, name, country, email, phone, contact, notes) => {
  return axios.put(URLS.RESELLERS.EDIT, {
    id: id,
    contactName: contact,
    countryCode: country,
    email: email.toLowerCase(),
    name: name,
    notes: notes,
    phone: phone,
  });
};

const resellersSearch = (search, searchBy) => {
  const params = { query: search, searchBy: searchBy };

  return axios.get(URLS.RESELLERS.SEARCH, {
    params: params,
  });
};

const resellersSearchPagination = (search, searchBy, pagesLastResellerId) => {
  const params = {
    query: search,
    searchBy: searchBy,
    pages_last_reseller_id: pagesLastResellerId,
  };

  return axios.get(URLS.RESELLERS.SEARCH_PAGINATION, {
    params: params,
  });
};

const resellersBuildingMonitoringGet = (id) =>
  axios.get(
    URLS.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.GET.replace(':id', id)
  );

const resellersBuildingMonitoringAdd = (resellerId, email) =>
  axios.post(URLS.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.ADD, {
    resellerId: resellerId,
    email: email.toLowerCase(),
  });

const resellersBuildingMonitoringDelete = (resellerId, email) =>
  axios.delete(URLS.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.DELETE, {
    data: {
      resellerId: resellerId,
      email: email.toLowerCase(),
    },
  });

const resellersBuildingMonitoringDisableAll = (resellerId) =>
  axios.put(URLS.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.DISABLE_ALL, {
    resellerId: resellerId,
  });

const resellersBuildingMonitoringEnableAll = (resellerId) =>
  axios.put(URLS.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.ENABLE_ALL, {
    resellerId: resellerId,
  });

// Reseller Users
const resellersUsersList = (resellerId) =>
  isReseller()
    ? axios.get(URLS.RESELLERS.USERS.GENERAL.replace('/:resellerId', ''))
    : axios.get(
        URLS.RESELLERS.USERS.GENERAL.replace(':resellerId', resellerId)
      );

const resellersUsersAdd = (resellerId, email) =>
  axios.post(
    isReseller()
      ? URLS.RESELLERS.USERS.GENERAL.replace('/:resellerId', '')
      : URLS.RESELLERS.USERS.GENERAL.replace(':resellerId', resellerId),
    {
      authorities: ['RESELLER_USER'],
      email: email.toLowerCase(),
    }
  );

const resellersUsersEdit = (resellerId, email, enabled, authorities) =>
  axios.put(
    isReseller()
      ? URLS.RESELLERS.USERS.GENERAL.replace('/:resellerId', '')
      : URLS.RESELLERS.USERS.GENERAL.replace(':resellerId', resellerId),
    {
      authorities: authorities,
      email: email.toLowerCase(),
      enabled: enabled,
    }
  );

const resellersUsersDelete = (resellerId, email) => {
  let url = URLS.RESELLERS.USERS.DELETE;

  url = isReseller()
    ? url.replace('/:resellerId', '')
    : url.replace(':resellerId', resellerId);
  url = url.replace(':username', email.toLowerCase());

  return axios.delete(url);
};
// /Reseller Users

//  Reseller Integrations
const resellersIntegrationsAdd = (resellerId, integration, permissions) => {
  return axios.post(URLS.RESELLERS.INTEGRATIONS.ADD, {
    resellerId: resellerId,
    type: integration,
    permissions: permissions,
  });
};

const resellersIntegrationsDelete = (integrationId) => {
  return axios.delete(
    URLS.RESELLERS.INTEGRATIONS.DELETE.replace(':integrationId', integrationId)
  );
};

const resellersPermissionsAdd = (integrationId, permission) => {
  return axios.post(URLS.RESELLERS.INTEGRATIONS.PERMISSIONS.ADD, {
    integrationId: integrationId,
    type: permission,
  });
};

const resellersPermissionsDelete = (integrationId, permission) => {
  return axios.delete(URLS.RESELLERS.INTEGRATIONS.PERMISSIONS.DELETE, {
    data: {
      integrationId: integrationId,
      type: permission,
    },
  });
};
// /Reseller Integrations

// Stock
const stockAddConfirm = (batchNumber, stocks) => {
  return axios.post(URLS.STOCK.ADD.CONFIRM, {
    batchNumber: batchNumber,
    stocks: stocks,
  });
};

const stockAddUpload = (data) => {
  return axios.post(URLS.STOCK.ADD.UPLOAD, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const stockAllocateCheck = (ids) => {
  return axios.get(URLS.STOCK.ALLOCATE.CHECK, {
    params: {
      sensor_ids: ids,
    },
  });
};

const stockAllocate = (
  reseller,
  country,
  isoLocaleCode,
  timeZoneId,
  sensors
) => {
  return axios.put(URLS.STOCK.ALLOCATE.CONFIRM, {
    resellerId: reseller,
    countryCode: country,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
    sensorIds: sensors,
  });
};

const stockDeallocate = (sensors) => {
  return axios.put(URLS.STOCK.DEALLOCATE, {
    sensorIds: sensors,
  });
};

const stockChangeResellerFindPrevious = (sensorsIds) => {
  return axios.get(URLS.STOCK.CHANGE.FIND_PREVIOUS, {
    params: {
      query: sensorsIds,
    },
  });
};

const stockChangeReseller = (
  reseller,
  country,
  isoLocaleCode,
  timeZoneId,
  previousResellers
) => {
  return axios.put(URLS.STOCK.CHANGE.CONFIRM, {
    resellerId: reseller,
    countryCode: country,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
    previousResellersSensorIds: previousResellers,
  });
};

const stockEdit = (sensorId, data) =>
  axios.put(URLS.STOCK.EDIT.replace(':id', sensorId), data);

const stockExample = () => {
  return axios.get(URLS.STOCK.EXAMPLE, {
    responseType: 'arraybuffer',
  });
};

const stockSearch = (filters, page) => {
  let params = { query: [], stock_search_by: [] };
  filters.forEach((filter) => {
    if (filter.search) {
      params.query.push(filter.search);
      params.stock_search_by.push(filter.searchBy);
    }
  });
  if (page) params.pages_last_sensor_id = page;

  return axios.get(URLS.STOCK.SEARCH, {
    params: params,
    paramsSerializer: (params) => {
      let url = '';

      params.query.forEach((search, index) => {
        url += `${index > 0 ? '&' : ''}${qs.stringify({
          query: search,
          stock_search_by: params.stock_search_by[index],
        })}`;
      });

      if (params.pages_last_sensor_id)
        url += `${url.length ? '&' : ''}${qs.stringify({
          pages_last_sensor_id: params.pages_last_sensor_id,
        })}`;

      return url;
    },
  });
};

const stockSummary = (category, country, phases, resellerId) => {
  let params = {};

  if (category) params.category = category;
  if (country) params.iso_country_code = country;
  if (phases) params.phases = phases;
  if (resellerId) params.reseller_id = resellerId;

  return axios.get(URLS.STOCK.SUMMARY, {
    params: params,
  });
};

const stockProviderSearch = (isoCountryCode) => {
  return axios.get(
    URLS.STOCK.PROVIDER.SEARCH.replace(':isoCountryCode', isoCountryCode)
  );
};

const stockProviderAdd = (name, isoCountryCode) => {
  return axios.post(URLS.STOCK.PROVIDER.ADD, {
    name,
    iso_country_code: isoCountryCode,
  });
};

const stockProviderEdit = (id, name, isoCountryCode) => {
  return axios.put(URLS.STOCK.PROVIDER.EDIT, {
    id,
    name,
    iso_country_code: isoCountryCode,
  });
};

const stockProviderDelete = (id) => {
  return axios.delete(URLS.STOCK.PROVIDER.DELETE.replace(':id', id));
};

const stockBlock = (stock) => {
  return axios.put(URLS.STOCK.BLOCK.CONFIRM, { sensorIds: stock });
};

const stockUnblock = (stock) => {
  return axios.put(URLS.STOCK.UNBLOCK.CONFIRM, { sensorIds: stock });
};

const stockBlockSearchById = (id) => {
  return axios.get(URLS.STOCK.BLOCK.SEARCH_BY_ID.replace(':id', id));
};

const stockBlockSearch = (lastId, isBlocked) => {
  const params = {};

  if (typeof isBlocked !== 'undefined') params.findByIsBlocked = isBlocked;

  if (lastId) params.lastId = lastId;

  return axios.get(URLS.STOCK.BLOCK.SEARCH, { params });
};

// Subscription
const subscriptionList = (search, searchBy, page) => {
  const params = { query: search, searchBy: searchBy };

  if (page) params.pagesLastId = page;

  return axios.get(URLS.SUBSCRIPTION.GENERAL, {
    params: params,
  });
};

const subscriptionDetails = (id) =>
  axios.get(URLS.SUBSCRIPTION.DETAILS.replace(':id', id));

const subscriptionAdd = (
  name,
  duration,
  hardwareType,
  paymentType,
  paymentRate,
  currency,
  tolerance,
  products,
  notes
) =>
  axios.post(URLS.SUBSCRIPTION.GENERAL, {
    currency: currency,
    duration: duration,
    hardwareType: hardwareType,
    name: name,
    notes: notes,
    paymentRate: paymentRate,
    paymentType: paymentType,
    products: products,
    tolerance: tolerance,
  });

const subscriptionEdit = (
  name,
  duration,
  hardwareType,
  paymentType,
  paymentRate,
  currency,
  tolerance,
  products,
  notes,
  id
) =>
  axios.put(URLS.SUBSCRIPTION.DETAILS.replace(':id', id), {
    currency: currency,
    duration: duration,
    hardwareType: hardwareType,
    name: name,
    notes: notes,
    paymentRate: paymentRate,
    paymentType: paymentType,
    products: products,
    tolerance: tolerance,
  });

/// Subscription Order
const subscriptionOrderList = (search, searchBy, page) => {
  let params = { query: search, searchBy: searchBy };

  if (page) params.pagesLastId = page;

  return axios.get(URLS.SUBSCRIPTION.ORDER.GENERAL, { params: params });
};

const subscriptionOrderDetails = (id) =>
  axios.get(URLS.SUBSCRIPTION.ORDER.DETAILS.replace(':id', id));

const subscriptionOrderAdd = (
  subscriptionId,
  resellerId,
  hardwareUnitPrice,
  sensorIds,
  countryCode,
  isoLocaleCode,
  timeZoneId,
  notes
) =>
  axios.post(URLS.SUBSCRIPTION.ORDER.GENERAL, {
    countryCode: countryCode,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
    hardwareUnitPrice: hardwareUnitPrice,
    notes: notes,
    resellerId: resellerId,
    sensorIds: sensorIds,
    subscriptionId: subscriptionId,
  });

const subscriptionOrderEdit = (id, notes) =>
  axios.put(URLS.SUBSCRIPTION.ORDER.DETAILS.replace(':id', id), {
    notes: notes,
  });

const subscriptionOrderDelete = (id) =>
  axios.delete(URLS.SUBSCRIPTION.ORDER.DETAILS.replace(':id', id));

const subscriptionOrderAttachList = (id) =>
  axios.get(URLS.SUBSCRIPTION.ORDER.ATTACH.GENERAL.replace(':id', id));

const subscriptionOrderAttachAdd = (id, data) =>
  axios.post(URLS.SUBSCRIPTION.ORDER.ATTACH.GENERAL.replace(':id', id), data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const subscriptionOrderAttachGet = (id, attachId) =>
  axios.get(
    URLS.SUBSCRIPTION.ORDER.ATTACH.DETAILS.replace(':id', id).replace(
      ':attachId',
      attachId
    )
  );

const subscriptionOrderAddSensors = (
  id,
  sensorIds,
  countryCode,
  isoLocaleCode,
  timeZoneId
) =>
  axios.put(URLS.SUBSCRIPTION.ORDER.SENSORS.ADD.replace(':id', id), {
    sensorIds: sensorIds,
    countryCode: countryCode,
    isoLocaleCode: isoLocaleCode,
    timeZoneId: timeZoneId,
  });

const subscriptionOrderRemoveSensors = (id, sensorIds) =>
  axios.delete(URLS.SUBSCRIPTION.ORDER.SENSORS.REMOVE.replace(':id', id), {
    data: { sensorIds: sensorIds },
  });

const subscriptionOrderDeadlinesList = (search, searchBy, page) => {
  let params = { query: search, searchBy: searchBy, page: page };

  return axios.get(URLS.SUBSCRIPTION.ORDER.DEADLINES.LIST, { params: params });
};

const subscriptionOrderDeadlinesRenew = (orderId) => {
  return axios.post(
    URLS.SUBSCRIPTION.ORDER.DEADLINES.RENEW.replace(':id', orderId)
  );
};

/// /Subscription Order
// /Subscription

// User
const userList = () => axios.get(URLS.USER.GENERAL);

const userAdd = (email, authorities) =>
  axios.post(URLS.USER.GENERAL, {
    authorities: authorities,
    email: email.toLowerCase(),
  });

const userEdit = (email, enabled, authorities) =>
  axios.put(URLS.USER.GENERAL, {
    authorities: authorities,
    email: email.toLowerCase(),
    enabled: enabled,
  });

const userDelete = (email) =>
  axios.delete(URLS.USER.DELETE.replace(':username', email.toLowerCase()));
// /User

const disaggregationSearch = (search, searchBy, granularity, date, page) => {
  const params = {
    searchBy: searchBy,
    query: search,
    date: date,
    granularity: granularity,
  };

  if (page) params.pagesLastId = page;

  return axios.get(URLS.DISAGGREGATION.SEARCH, {
    params: params,
  });
};

const stockActivateSmartCable = (sensorId, status) => {
  let url = URLS.STOCK.ACTIVATE_SMART_CABLE;
  url = url.replace(':sensorId', sensorId);
  url = url.replace(':status', status);

  return axios.put(url);
};

//BEHAVIOURAL_MONITORING_APP
const getBehaviouralMonitoringAccount = (deviceId, integratorId) => {
  const params = {
    integratorId: integratorId,
  };

  return axios.get(URLS.BEHAVIOURAL_MONITORING.GET.replace(':id', deviceId), {
    params: params,
  });
};

const enableBehaviouralMonitoringApp = (id) => {
  return axios.put(URLS.BEHAVIOURAL_MONITORING.ENABLE.replace(':id', id));
};

const disableBehaviouralMonitoringApp = (id) => {
  return axios.put(URLS.BEHAVIOURAL_MONITORING.DISABLE.replace(':id', id));
};

const getBehaviouralMonitoringInformation = (deviceId, integratorId) => {
  const params = {
    integratorId: integratorId,
  };

  return axios.get(
    URLS.BEHAVIOURAL_MONITORING.GET_INFO.replace(':id', deviceId),
    {
      params: params,
    }
  );
};

const API = {
  AUTH: {
    LOGIN: login,
    LOGOUT: logout,
    RESET: authResetPassword,
  },
  ACCOUNTS: {
    SEARCH: accountsSearch,
    DETAILS: accountDetails,
    SMART_METER_DETAILS: accountSmartMeterDetails,
    RESTRICTED_DETAILS: accountRestrictedDetails,
    TEMPORARY_PASSWORD: {
      DELETE: accountTemporaryPasswordDelete,
      GENERATE: accountTemporaryPasswordGenerate,
    },
    INSTALLATIONS: accountInstallationsSearch,
    TERMS_AGREEMENT: accountTermsAgreement,
  },
  COUNTRY: {
    ADD: countryAdd,
    EDIT: countryEdit,
    GENERAL: countryGeneral,
    SEARCH: countrySearch,
    SUMMARY: countrySummary,
    AVAILABLE: countriesAvailable,
  },
  DISAGGREGATION: {
    SEARCH: disaggregationSearch,
  },
  LOCALES: {
    SEARCH: localesSearch,
  },
  TIME_ZONES: {
    SEARCH: timeZonesSearch,
  },
  RESELLERS: {
    ADD: resellersAdd,
    EDIT: resellersEdit,
    SEARCH: resellersSearch,
    SEARCH_PAGINATION: resellersSearchPagination,
    USERS: {
      ADD: resellersUsersAdd,
      DELETE: resellersUsersDelete,
      EDIT: resellersUsersEdit,
      LIST: resellersUsersList,
    },
    INTEGRATIONS: {
      ADD: resellersIntegrationsAdd,
      DELETE: resellersIntegrationsDelete,
      PERMISSIONS: {
        ADD: resellersPermissionsAdd,
        DELETE: resellersPermissionsDelete,
      },
      BUILDING_MONITORING: {
        USERS: {
          ADD: resellersBuildingMonitoringAdd,
          DELETE: resellersBuildingMonitoringDelete,
          DISABLE_ALL: resellersBuildingMonitoringDisableAll,
          ENABLE_ALL: resellersBuildingMonitoringEnableAll,
          GET: resellersBuildingMonitoringGet,
        },
      },
    },
  },
  DEVICES: {
    REPORT: devicesReport,
  },
  SENSORS: {
    DETAILS: sensorDetails,
    RESET: {
      MACHINE_LEARNING: resetMachineLearning,
      FULL: fullReset,
    },
    CHANGE_LOCALE: changeLocale,
    EVENTS_EXPORTING: {
      SEND_TO_EMAIL: sensorEventsExporting,
      HISTORY: sensorEventsExportingHistory,
    },
    REPORTS_GENERATION_STATUS: reportsGenerationStatus,
    DELETE_SENSOR_EVENTS: sensorEventsDelete,
    PURGE: purgeSensor,
  },
  SMART_METERS: {
    SEARCH: smartMetersSearch,
    DETAILS: smartMetersDetails,
    PROPERTY_DETAILS: {
      GET: smartMeterPropertyDetails,
      PUT: createOrChangeSmartMeterPropertyDetails,
      APPLIANCES: {
        GET: smartMeterPropertyAppliances,
        PUT: changeSmartMeterPropertyAppliances,
      },
    },
    SMART_METER_APP: activateSmartMeterApp,
    SETUP_VALIDATE: smartMeterSetupValidate,
    SETUP: smartMeterSetup,
    SETUP_EXAMPLE: getSmartMeterSetupExample,
  },
  STOCK: {
    DETAILS: stockDetails,
    GENERAL_STATUS: stockGeneralStatus,
    PROPERTY_DETAILS: {
      GET: stockPropertyDetails,
      PUT: changeStockPropertyDetails,
      APPLIANCES: {
        GET: stockPropertyAppliances,
        POST: changeStockPropertyAppliances,
      },
    },
    REPORT: {
      CREATE: stockReportCreate,
      HISTORY: stockReportHistory,
      PURGE: stockReportPurge,
      SEND: stockReportSend,
    },
    ADD: {
      CONFIRM: stockAddConfirm,
      UPLOAD: stockAddUpload,
    },
    ALLOCATE: {
      CHECK: stockAllocateCheck,
      CONFIRM: stockAllocate,
    },
    EDIT: stockEdit,
    DEALLOCATE: stockDeallocate,
    CHANGE: {
      FIND_PREVIOUS: stockChangeResellerFindPrevious,
      CONFIRM: stockChangeReseller,
    },
    EXAMPLE: stockExample,
    SEARCH: stockSearch,
    SUMMARY: stockSummary,
    PROVIDER: {
      DELETE: stockProviderDelete,
      // DETAILS: stockProviderDetails,
      SEARCH: stockProviderSearch,
      ADD: stockProviderAdd,
      EDIT: stockProviderEdit,
    },
    BLOCK: {
      CONFIRM: stockBlock,
      SEARCH: stockBlockSearch,
      SEARCH_BY_ID: stockBlockSearchById,
    },
    UNBLOCK: {
      CONFIRM: stockUnblock,
    },
    ACTIVATE_SMART_CABLE: stockActivateSmartCable,
  },
  SUBSCRIPTION: {
    ADD: subscriptionAdd,
    DETAILS: subscriptionDetails,
    EDIT: subscriptionEdit,
    LIST: subscriptionList,
    ORDER: {
      ADD: subscriptionOrderAdd,
      ATTACH: {
        ADD: subscriptionOrderAttachAdd,
        GET: subscriptionOrderAttachGet,
        LIST: subscriptionOrderAttachList,
      },
      DELETE: subscriptionOrderDelete,
      DETAILS: subscriptionOrderDetails,
      EDIT: subscriptionOrderEdit,
      LIST: subscriptionOrderList,
      SENSORS: {
        ADD: subscriptionOrderAddSensors,
        REMOVE: subscriptionOrderRemoveSensors,
      },
      DEADLINES: {
        LIST: subscriptionOrderDeadlinesList,
        RENEW: subscriptionOrderDeadlinesRenew,
      },
    },
  },
  TARIFFS: {
    SEARCH: tariffSearch,
    SMART_METER_SEARCH: smartMeterTariffSearch,
  },
  USER: {
    ADD: userAdd,
    DELETE: userDelete,
    EDIT: userEdit,
    LIST: userList,
  },
  BEHAVIOURAL_MONITORING: {
    GET: getBehaviouralMonitoringAccount,
    ENABLE: enableBehaviouralMonitoringApp,
    DISABLE: disableBehaviouralMonitoringApp,
    GET_INFO: getBehaviouralMonitoringInformation,
  },
};

export default API;
