import { React } from 'react';
import { MdSmartphone } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import Title from '../../elements/title';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';

function UserDetails(props) {
  const { change, client, isSmartMeter } = props;

  return (
    <div id="user-details" className="xs-4">
      {props.feedbackMessage ? props.feedbackMessage : null}

      <Title element="h6" text="User Details" />
      {!isSmartMeter ? (
        <>
          <div>
            <Input
              change={change}
              disabled={true}
              name="client.reseller"
              placeholder="Reseller"
              type="text"
              value={(client.reseller && client.reseller.name) || ''}
            />
          </div>
        </>
      ) : null}
      {client.property != null ? (
        <>
          <div>
            <Input
              change={change}
              disabled={true}
              name="client.country"
              placeholder="Country"
              type="text"
              value={client.property.isoCountryCode || ''}
            />
          </div>
          <div>
            <Input
              change={change}
              disabled={true}
              name="client.currency"
              placeholder="Currency"
              type="text"
              value={
                client.property.currencyInfo.internationalCurrencySymbol || ''
              }
            />
          </div>
          <div>
            <Input
              change={change}
              disabled={true}
              name="client.language"
              placeholder="Language"
              type="text"
              value={client.property.isoLocaleCode || ''}
            />
          </div>
          <div>
            <Input
              change={change}
              disabled={true}
              name="client.timezone"
              placeholder="Timezone"
              type="text"
              value={client.property.timeZoneId || ''}
            />
          </div>
          {isSmartMeter && client.account && (
            <div id="smart-meter-app-email">
              <Text inline={true} text={client.account.email} />
              <MdSmartphone size="2em" data-tip="Smart Meter App is enabled" />
              <ReactTooltip />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default UserDetails;
