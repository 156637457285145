import { UNAVAILABLE_MESSAGE } from '../../components/constants';

import Text from '../../elements/text';
import Title from '../../elements/title';

function AccountDetails(props) {
  const { client } = props;

  return (
    <div className="xs-4" id="account-details">
      <Title element="h6" text="Account Details" />
      <div>
        <Text bold={true} inline={true} text="MAC Address" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.macAddress || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="SSID" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.ssid || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Key" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.key || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="# Phases" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.phases || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Category" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.category || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Status" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.status || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Clamp (A)" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.clampSize || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Production Date" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.productionDate || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Batch ID" />
        <Text
          bold={false}
          inline={true}
          text={client.stockUpload.batchNumber || UNAVAILABLE_MESSAGE}
        />
      </div>
    </div>
  );
}

export default AccountDetails;
