import React from 'react';
import { Link } from 'react-router-dom';

import Private from '../../components/auth/private';
import { USER_AUTHORITIES } from '../../components/constants';

import Text from '../../elements/text';
import Title from '../../elements/title';

import './admin-tools.sass';

const checkPrivacies = (link, userPrivacies) => {
  if (!link.privaciesNeeded) {
    return true;
  }

  return link.privaciesNeeded.every(
    (privacyNeeded) => userPrivacies[privacyNeeded] === true
  );
};

const checkSmartBox = (link, category) => {
  if (!link.smartBoxNeeded) {
    return true;
  }

  return category === 'SMARTBOX';
};

const checkAccount = (link, account) => {
  if (!link.accountNeeded) {
    return true;
  }

  if (link.accountNeeded === 'allow') {
    if (account === null) {
      return false;
    } else {
      return true;
    }
  }

  if (link.accountNeeded === 'block') {
    if (account === null) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

function AdminTools({
  id,
  isSmartMeter,
  integratorId,
  privacy,
  category,
  account,
}) {
  const sensorBase = `/sensor/${id}/admintools/`;
  const sensorLinks = [
    [
      {
        authNeeded: [USER_AUTHORITIES.ADMIN],
        pathname: `${sensorBase}changelocale`,
        state: 'changelocale',
        text: 'Change Locale',
      },
    ],
    [
      {
        authNeeded: [
          USER_AUTHORITIES.ADMIN,
          USER_AUTHORITIES.BUSINESS,
          USER_AUTHORITIES.MACHINE_LEARNING,
          USER_AUTHORITIES.RESELLER_ADMIN,
          USER_AUTHORITIES.RESELLER_USER,
          USER_AUTHORITIES.TECH,
        ],
        pathname: `${sensorBase}eventsexporting`,
        state: 'eventsexporting',
        text: 'Events Exporting',
      },
      {
        authNeeded: [
          USER_AUTHORITIES.ADMIN,
          USER_AUTHORITIES.BUSINESS,
          USER_AUTHORITIES.MACHINE_LEARNING,
          USER_AUTHORITIES.RESELLER_ADMIN,
          USER_AUTHORITIES.RESELLER_USER,
          USER_AUTHORITIES.TECH,
        ],
        pathname: `${sensorBase}eventsexportinghistory`,
        state: 'eventsexportinghistory',
        text: 'Events Exporting History',
      },
      {
        authNeeded: [USER_AUTHORITIES.ADMIN],
        pathname: `${sensorBase}activatesmartbox`,
        state: 'activatesmartcable',
        text: 'Activate smartbox',
        smartBoxNeeded: true,
      },
    ],
    [
      {
        authNeeded: [
          USER_AUTHORITIES.ADMIN,
          USER_AUTHORITIES.BUSINESS,
          USER_AUTHORITIES.MACHINE_LEARNING,
          USER_AUTHORITIES.RESELLER_ADMIN,
          USER_AUTHORITIES.RESELLER_USER,
          USER_AUTHORITIES.TECH,
        ],
        pathname: `${sensorBase}newreportgenerationstatus`,
        state: 'newreportgenerationstatus',
        text: 'New report generation status',
      },
    ],
    [
      {
        authNeeded: [USER_AUTHORITIES.ADMIN],
        pathname: `${sensorBase}deletesensorevents`,
        state: 'deletesensorevents',
        text: 'Delete Sensor Events',
        caution: true,
      },
    ],
  ];

  const smartMeterBase = `/smart-meter/${integratorId}/${id}/admintools/`;
  const smartMeterLinks = [
    [
      {
        authNeeded: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
        accountNeeded: 'block',
        pathname: `${smartMeterBase}smartmeterapp`,
        state: 'smartmeterapp',
        text: 'Smart Meter App',
      },
    ],
    [
      {
        authNeeded: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
        accountNeeded: 'block',
        pathname: `${smartMeterBase}newreportgenerationstatus`,
        state: 'newreportgenerationstatus',
        text: 'New Report Generation Status',
      },
    ],
  ];

  const links = isSmartMeter ? smartMeterLinks : sensorLinks;

  return (
    <div id="admin-tools">
      <Title element="h6" text="Admin Tools" />
      {links.map((arr, index) => (
        <div className="col xs-4" key={index}>
          <ul>
            {arr
              .filter((link) => checkPrivacies(link, privacy))
              .filter((link) => checkSmartBox(link, category))
              .filter((link) => checkAccount(link, account))
              .map((link) => (
                <Private authNeeded={link.authNeeded} key={link.pathname}>
                  <li>
                    <Link to={{ pathname: link.pathname, state: link.state }}>
                      <Text
                        bold={true}
                        caution={link.caution}
                        inline={true}
                        text={link.text}
                      />
                    </Link>
                  </li>
                </Private>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default AdminTools;
